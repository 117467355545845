<template>
  <div class="user-home row">
    <div class="col-md-3 bg-light box">
      <img
        class="rounded mx-auto d-block mt-5"
        style="width: 200px;height: 200px"
        :src="$baseUploadURL + user.image"
        @error="$errorHandler"
      />
      <br />
      <br />
      <h3 align="center">مرحباً اكاديمية {{ user.userName }}</h3>
    </div>
    <div class="col-md-9">
      <DataTable
        ref="dt"
        :value="list"
        :paginator="true"
        class="p-datatable-customers"
        style="padding-top: 46px;padding-left: 14px;"
        :rows="5"
        dataKey="id"
        :rowHover="true"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25, 50, 100]"
        currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
      >
        <template #empty>
          لا يوجد بيانات
        </template>
        <template #loading>
          يتم تحميل البيانات. يرجي الانتظار...
        </template>

        <Column
          field="image"
          header="الشعار"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <img
              :src="$baseUploadURL + slotProps.data.image"
              class="dt-image"
            />
          </template>
        </Column>

        <Column field="name" header="الاسم" sortable="true" />
        <Column field="location" header="المكان" sortable="true" />

        <!-- <Column
          field="status"
          header="الحاله"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.status"
              class="customer-badge status-qualified"
              >فعال</span
            >
            <span v-else class="customer-badge status-unqualified"
              >تم الانتهاء</span
            >
          </template>
        </Column> -->

        <Column
          field="id"
          bodyStyle="text-align: center; overflow: visible;direction: ltr;"
        >
          <template #body="slotProps">
            <!-- <SplitButton
              label="التحكم"
              icon="pi pi-plus"
              :disabled="!slotProps.data.status"
              :model="[
                {
                  label: 'المواليد وفرق',
                  to: '/user/addYears/' + slotProps.data.id,
                },
                {
                  label: 'المدربين',
                  to: '/user/coach/' + slotProps.data.id,
                },
                {
                  label: 'الادريين',
                  to: '/user/mindful/' + slotProps.data.id,
                },
                {
                  label: 'المدرين الفنين',
                  to: '/user/technical_manager/' + slotProps.data.id,
                },
                {
                  label: 'اللاعبين',
                  to: '/user/players/' + slotProps.data.id,
                },
              ]"
              class="p-button-help" v-tooltip="'تحميل'"
            ></SplitButton> -->

            <Button
              label="التحكم"
              icon="pi pi-plus"
              @click="$router.push('/home/' + slotProps.data.id)"
              class="p-button-help" v-tooltip="'تحميل'"
            ></Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: true,
      user: JSON.parse(localStorage.user),
    };
  },
  methods: {
    getData() {
      this.$http.get(`league?status=1`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          if (this.list.length == 1) {
            this.$router.push('/home/' + this.list[0].id);
          }
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    delete localStorage.logoImage;
    this.$eventHub.$emit('image', null);
    this.getData();
  },
};
</script>

<style>
.user-home {
  height: 100%;
}

.box {
  height: 100%;
  color: #000;
}
.HomeBtn,
.BoxSec .NextPage {
  margin: 20px 0;
}
.avatar-upload,
.BoxSec .rowSec.LowLabel label,
.BoxSec .rowSec.LowLabel .form-control,
.BoxSec .rowSec .form-control,
.BoxSec .rowSec label,
.BoxSec .NextPage,
.HomeBtn {
  width: 100%;
  display: block;
  margin: 10px 0;
}
.HomeBtn {
  display: inline-block;
  background-color: #0a8072;
  color: #fff !important;
  border-radius: 25px;
  width: 40%;
  padding: 15px;
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
}

.HomeBtn:hover {
  color: #fff;
  text-decoration: none;
  background-color: #000;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
  background-color: #c8e6c9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #ffcdd2;
  color: #c63737;
}
</style>
